import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper, Row,
} from "@tanstack/react-table";
import {
  Center,
  Flex, Image,
  Input,
  Link,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text, Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { ILimitFenceCardMaterial, ILimitFenceCardMaterials, INewLimitFenceCardMaterial } from "../../api/limitFenceCards/types";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import styles from "../lib/input/input.module.scss";
import tableStyles from "../lib/table/table.module.scss";
import calendar from "../../images/svg/calendar/calendar.svg";
import {ChakraModalProps} from "../../shared/types/modalProps";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import {BlockTitle, Title} from "../lib/title/Title";
import {PrimaryButton} from "../lib/button/Button";
import SuccessLogo from "../../images/png/success.png";
import {addMaterialsToFenceCard, changeCurrentLimitFenceCard, fetchSingleLimitFenceCard, fetchSingleLimitFenceCardMaterials, resetCurrentLimitFenceCard} from "../../store/slices/limitFenceCards";
import close from "../../images/svg/close/close.svg";
import { getSingleLimitFenceCardMaterials } from "../../api/limitFenceCards";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid'
import { CloseIcon } from "@chakra-ui/icons";

interface IMaterialsTableProps {
  searchValue?: string;
  setMaterialRow?: (material: INewLimitFenceCardMaterial | undefined, index: number | undefined) => void;
  setDisableApprove?: (arg:boolean) => void;
  disabledChange?: boolean;
  purchase_request?: boolean;
}

interface IEditModal extends ChakraModalProps {
  row?: Row<INewLimitFenceCardMaterial>;
}

const columnHelper = createColumnHelper<INewLimitFenceCardMaterial>();

const MeasureCell = (info: CellContext<INewLimitFenceCardMaterial, string>) => (
  <Center>{info.renderValue()}</Center>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;

const AmountCell = (info: CellContext<INewLimitFenceCardMaterial, string>, setDisable: (arg:boolean) => void, setMaterialRow?: (material: INewLimitFenceCardMaterial | undefined, index: number | undefined) => void, disabledChange?: boolean) => {
  const { row } = info;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(String(Number(info.renderValue()!).toFixed(3)));

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    formatValue = parseFloat(formatValue).toFixed(3);
    setValue(formatValue);

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            amount: Number(formatValue).toFixed(3),
          };
        }
        return material;
      });

      const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
    }
  }

  useEffect(() => {
    if (currentLimitFenceCard?.materials) {
      const hasChanges = currentLimitFenceCard.materials.some(
        (material, index) => material.amount !== info.row.original.amount && index === info.row.index
      );
      if (hasChanges) {
        setDisable(true);
      }
    }
  }, [currentLimitFenceCard?.materials, info.row.original.amount, info.row.index, setDisable]);

  useEffect(() => {
    setValue(String(Number(row.original.amount).toFixed(3)) ?? "")
  }, [row.original.amount]);

  return (
    <>
      {!disabledChange ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={`${styles.input_container} ${styles.table_input}`}>
              <input
                value={value}
                id={info.row.original.material_guid}
                onChange={(e) =>
                  setValue(e.target.value.replace(/[^0-9.]/g, ""))
                }
                onBlur={handleBlur}
                onKeyDown={(e) =>
                  e.key === "Enter" && (e.target as HTMLInputElement).blur()
                }
                onClick={(e) => e.stopPropagation()}
                disabled={disabledChange}
                type="number"
              />
            </div>
          </div>
        </div>
      ) : (
        <Text>{String(Number(info.renderValue()).toFixed(3))}</Text>
      )}
    </>
  );
};

const PeriodCell = (info: CellContext<INewLimitFenceCardMaterial, string>, disabledChange?: boolean) => {
  const inputStyle = `url(${calendar}) no-repeat 100% 50% content-box`;
  const isDisabled = Number(info.row.original.amount) === 0;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(info.row.original.period);

  useEffect(() => {
    setValue(isDisabled ? '' : info.row.original.period);
  }, [info.row.original.period, isDisabled]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            period: e.target.value,
          };
        }
        return material;
      });

      const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
    }
    
  }

  useEffect(() => {
    setValue(info.row.original.period);
  }, [info.row.original.period]);

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'c' || event.key === 'с') {
        event.preventDefault();
        navigator.clipboard.writeText(`${value}`)
      }
    }

    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'v' || event.key === 'м') {
        event.preventDefault();
        navigator.clipboard.readText()
        .then((clipText: string) => {
          setValue(clipText)
        })
      }
    }
  }

  return (
    !disabledChange ? (
      <Input
        h="36px"
        rounded="md"
        type="date"
        borderColor="#E3E7EF"
        id={info.row.original.guid}
        value={value}
        onChange={onDateChange}
        onBlur={handleBlur}
        className={tableStyles.input_date}
        onClick={(e) => {
          e.stopPropagation()
        }}
        disabled={isDisabled}
        onKeyDown={handleKeyDown}
      />
    ) : (
      <Text>{info.renderValue() ? dayjs(info.renderValue()).format("DD.MM.YYYY") : ""}</Text>
    )
  )
}

const CommentCell = (info: CellContext<INewLimitFenceCardMaterial, string>, onOpenCommentForm: (e: React.MouseEvent, row: Row<INewLimitFenceCardMaterial>) => void, disabledChange?: boolean) => {
  const isDisabled = Number(info.row.original.amount) === 0

  return (
    disabledChange ? (
      <Text  noOfLines={2}>{info.renderValue()}</Text>
    ) : (
      info.renderValue() ? (
        <Text noOfLines={2} onClick={(e: React.MouseEvent) => onOpenCommentForm(e, info.row)}>{info.renderValue()}</Text>
      ) : (
        <Center color={isDisabled ? "#AAB0C1" : "#2AB6A5"} cursor={isDisabled ? "auto" : "pointer"} onClick={(e: React.MouseEvent) => !isDisabled && onOpenCommentForm(e, info.row)} >Добавить</Center>
      )
    )
  );
}

const DeleteCell = (info: CellContext<INewLimitFenceCardMaterial, string>) => {
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    const changedMaterials = currentLimitFenceCard?.materials.filter((el) => el.table_guid !== info.row.original.table_guid)

    const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: changedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
  }

  return (
    <>
      {info.row.original.isNewItem && (
        <Flex w="30px" justifyContent="center">
          <CloseIcon color="#8089A2" sx={{
            "&:hover": {
              opacity: 0.6
            }
          }} onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            handleDelete();
          }} />
        </Flex>
      )}
    </>
  )
}

const CustomNameCell = (info: CellContext<INewLimitFenceCardMaterial, string>, disabledChange?: boolean) => {
  const { currentLimitFenceCard } = useAppSelector((state) => state.limitFenceCards);
  const dispatch = useAppDispatch();
  const isEdited = info.row.original.ssom_id === "";
  const [value, setValue] = useState<string | undefined>(info.renderValue() ?? "")
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setValue(rawValue);
    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            custom_material_name: rawValue,
          };
        }
        return material;
      });

      const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
    }
  }


  useEffect(() => {
    setValue(info.row.original.custom_material_name)
  }, [currentLimitFenceCard?.materials, info.row.original.custom_material_name])

  return (
    <>
      {isEdited && (<div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={`${styles.input_container} ${styles.table_input}`}>
            <input
              value={value}
              onChange={(e) =>
                setValue(e.target.value)
              }
              onBlur={handleBlur}
              onKeyDown={(e) =>
                e.key === "Enter" && (e.target as HTMLInputElement).blur()
              }
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>)}

      {!isEdited && info.renderValue()}
    </>
  )
}

const CustomUnitCell = (info: CellContext<INewLimitFenceCardMaterial, string>, disabledChange?: boolean) => {
  const { currentLimitFenceCard } = useAppSelector((state) => state.limitFenceCards);
  const dispatch = useAppDispatch();
  const isEdited = info.row.original.ssom_id === "";
  const [value, setValue] = useState<string | undefined>(info.renderValue() ?? "")
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setValue(rawValue);

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            custom_unit_of_measurement: rawValue,
          };
        }
        return material;
      });

      const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
    }
  }

  useEffect(() => {
    setValue(info.row.original.custom_unit_of_measurement)
  }, [currentLimitFenceCard?.materials, info.row.original.custom_unit_of_measurement])

  return (
    <>
      {isEdited && (<div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={`${styles.input_container} ${styles.table_input}`}>
            <input
              value={value}
              onChange={(e) =>
                setValue(e.target.value)
              }
              onBlur={handleBlur}
              onKeyDown={(e) =>
                e.key === "Enter" && (e.target as HTMLInputElement).blur()
              }
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>)}
      {!isEdited && info.renderValue()}
    </>
  )
}

const CustomPriceCell = (info: CellContext<INewLimitFenceCardMaterial, string>, disabledChange?: boolean) => {
  const { currentLimitFenceCard } = useAppSelector((state) => state.limitFenceCards);
  const dispatch = useAppDispatch();
  const isEdited = info.row.original.ssom_id === "";
  const [value, setValue] = useState<string | undefined>(String(Number(info.renderValue()!).toFixed(2)))
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    formatValue = parseFloat(formatValue).toFixed(2);
    setValue(formatValue);

    const index = info.row.index;
    if (index !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = [...currentLimitFenceCard.materials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
        tender_price: formatValue,
      };
      const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            tender_price: formatValue,
          };
        }
        return material;
      });

      const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
    }
  }

  useEffect(() => {
    setValue(String(Number(info.row.original.tender_price).toFixed(2)))
  }, [info.row.original.tender_price])

  return (
    <>
      {!disabledChange ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={`${styles.input_container} ${styles.table_input}`}>
              <input
                id={uuidv4()}
                type="number"
                value={value}
                onChange={(e) =>
                  setValue(e.target.value.replace(/[^0-9.]/g, ""))
                }
                onBlur={handleBlur}
                onKeyDown={(e) =>
                  e.key === "Enter" && (e.target as HTMLInputElement).blur()
                }
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        </div>
      ) : (
        <Text>{info.renderValue() ? info.renderValue() : ""}</Text>
      )}

    </>
  )
}

const SummCell = (info: CellContext<INewLimitFenceCardMaterial, string>) => {
  const amount = Number(info.row.original.amount);
  const tenderPrice = Number(info.row.original.tender_price);

  const value = isNaN(amount) || isNaN(tenderPrice)
    ? '' // Возвращаем пустую строку, если amount или tenderPrice - NaN
    : (amount * tenderPrice).toFixed(2);

  return (
    <Text minW="150px">{formatAmount(value)}</Text>
  );
};

export function MaterialsTable({searchValue, setMaterialRow, setDisableApprove, disabledChange, purchase_request}: IMaterialsTableProps) {
  const dispatch = useAppDispatch();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );

  const [wrap, setWrap] = useState<boolean>(false);

  const toggleTableWrap = (): void => {
    setWrap(!wrap);
  }

  const [filteredMaterials, setFilteredMaterials] = useState(currentLimitFenceCard?.materials);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [row, setRow] = useState<Row<INewLimitFenceCardMaterial>>();

  const onOpenCommentForm = useCallback((e: React.MouseEvent, row: Row<INewLimitFenceCardMaterial>) => {
    e.stopPropagation();
    setRow(row);
    onOpen();
  }, [onOpen]);

  const onMaterialTableClick = (row: Row<INewLimitFenceCardMaterial>) => {
    row.toggleSelected();
    setMaterialRow && setMaterialRow(row.getIsSelected() ? undefined : row.original, row.getIsSelected() ? undefined : row.index)
  }

  const setDisable = useCallback((arg:boolean) => {
    if (setDisableApprove) {
      setDisableApprove(arg);
    }
  },[setDisableApprove]);

  useEffect(() => {
    const filterMaterials = (materials:any) => {
      if (searchValue) {
        const searchTerm = searchValue.toLowerCase().trim();
        return materials.filter((item:any) =>
          (item.material_name ?? "").toLowerCase().includes(searchTerm) ||
          (item.custom_material_name ?? "").toLowerCase().includes(searchTerm) ||
          String(item.amount ?? "").toLowerCase().includes(searchTerm) ||
          String(item.ssom_order_number ?? "").toLowerCase().includes(searchTerm) ||
          (item.analog_material ?? "").toLowerCase().includes(searchTerm) ||
          (item.unit_of_measurement ?? "").toLowerCase().includes(searchTerm) ||
          String(item.indicative_price ?? "").toLowerCase().includes(searchTerm) ||
          String(item.tender_quantity ?? "").toLowerCase().includes(searchTerm) ||
          String(item.tender_price ?? "").toLowerCase().includes(searchTerm) ||
          (item.tender_solution ?? "").toLowerCase().includes(searchTerm) ||
          (item.inventory_supervisor ?? "").toLowerCase().includes(searchTerm) ||
          (item.period ?? "").toLowerCase().includes(searchTerm) ||
          (item.comment ?? "").toLowerCase().includes(searchTerm) ||
          (item.custom_unit_of_measurement ?? "").toLowerCase().includes(searchTerm) ||
          String(item.order_number ?? "").toLowerCase().includes(searchTerm)
        );
      } else {
        return materials;
      }
    };

    const filtered = filterMaterials(currentLimitFenceCard?.materials || []);
    setFilteredMaterials(!wrap ? filtered : filtered.filter((item:any) => item.amount !== 0 && item.amount !== null && item.amount !== undefined && item.amount !== '' && item.amount !== '0.000'));
  }, [searchValue, currentLimitFenceCard?.materials, wrap]);


  const columns = useMemo<Array<ColumnDef<INewLimitFenceCardMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("order_number", {
            header: "№",
          }),
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
            cell: (info) => formatAmount(info.renderValue())
          }),
        ],
      }),

      columnHelper.group({
        id: "tender",
        header: () => CenterCell("Решение по тендеру"),
        columns: [
          columnHelper.accessor("tender_supplier_name", {
            header: "Поставщик",
          }),
          columnHelper.accessor("tender_quantity", {
            header: "Количество",
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
            cell: (info) => formatAmount(info.renderValue())
          }),
          columnHelper.accessor("tender_solution", {
            header: "Решение по тендеру",
          }),
        ],
      }),
      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("amount", {
            header: "Количество",
            meta: {cellClassName: !disabledChange ? "changeable" : ""},
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("summ", {
            header: "Сумма",
            cell: (info) => SummCell(info)
          }),
          columnHelper.accessor("period", {
            header: "Период поставки",
            meta: {cellClassName: !disabledChange ? "changeable" : ""},
            cell: (info) => PeriodCell(info, disabledChange),
          }),
          columnHelper.accessor("comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm, disabledChange),
            meta: {cellClassName: !disabledChange ? "changeable" : ""}
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable]
  );

  const columnsСreate = useMemo<Array<ColumnDef<INewLimitFenceCardMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("delete", {
            header: "",
            cell: DeleteCell
          }),
          columnHelper.accessor("order_number", {
            header: "№",
          }),
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("custom_material_name", {
            header: "Номенклатура строкой",
            cell: (info) => CustomNameCell(info, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("custom_unit_of_measurement", {
            header: "Ед. изм. строкой",
            cell: (info) => CustomUnitCell(info, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
            cell: (info) => info.renderValue() !== "" && formatAmount(info.renderValue())
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
            cell: (info) => CustomPriceCell(info, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
        ],
      }),

      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("amount", {
            header: "Количество",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("summ", {
            header: "Сумма",
            cell: (info) => SummCell(info)
          }),
          columnHelper.accessor("period", {
            header: "Период поставки",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => PeriodCell(info, disabledChange),
          }),
          columnHelper.accessor("comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable, filteredMaterials]
  );

  return (
    <Flex flexDirection="column" gap="24px">
      <Table
        data={filteredMaterials ?? []}
        columns={purchase_request ? columnsСreate : columns}
        rowClick={onMaterialTableClick}
        dblClickDisabled={true}
      />
      <Flex color="#2AB6A5" alignSelf="end">
        <Link onClick={toggleTableWrap}>{`${wrap ? "Показать все" : "Скрыть пустые"} строки`}</Link>
      </Flex>
      <EditModal isOpen={isOpen} onClose={onClose} row={row} />
    </Flex>
  );
}

function EditModal(props: IEditModal) {
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const {onClose, isOpen, row} = props;

  const [localComment, setLocalComment] = useState(row?.original.comment ? row?.original.comment : "");

  useEffect(() => {
    setLocalComment(row?.original.comment ? row?.original.comment : "");
  }, [row]);

  const dispatch = useAppDispatch();

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();

  const handleCommentModalClose = () => {
    if (row) {
      const rowId = row.original.table_guid;
      if (rowId !== undefined && currentLimitFenceCard?.materials) {
        const updatedMaterials: INewLimitFenceCardMaterial[] = currentLimitFenceCard?.materials.map((material) => {
          if (material.table_guid === rowId) {
            return {
              ...material,
              comment: localComment ?? "",
            };
          }
          return material;
        });

        const updatedLimitFenceCard = { ...currentLimitFenceCard, materials: updatedMaterials };
        dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard))
      }

      onClose();
      onSuccessOpen();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Image cursor="pointer" src={close} float="right" onClick={onClose} />
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Комментарий</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Textarea h="360px" value={localComment} onChange={(e) => {setLocalComment(e.target.value)}}/>
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton
              w="full"
              fontSize="16px"
              onClick={handleCommentModalClose}
            >
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const {isOpen, onClose} = props
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Комментарий успешно изменён</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
