import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { ChakraMenuList } from "../../components/lib/menu/ChakraMenuList";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { DocumentPageBlock } from "../../components/SingleOrderLetterComponents/DocumentPageTab/DocumentPageBlock";
import { AdditionalInformationBlock } from "../../components/SingleOrderLetterComponents/AdditionalInformationBlock";
import { FilesBlock } from "../../components/SingleOrderLetterComponents/FilesTab/FilesBlock";
import { singleOrderLetterPageTabs, singleOrderLetterPageTabsWithoutMaterials } from "../../shared/mock/purchasePageData";
import {ReactComponent as MenuIcon} from "../../images/svg/menu/more.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { MaterialsTable } from "../../components/SingleOrderLetterComponents/MaterialsTable"
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { attachFilesToDirrectionLetter, fetchApproveLetter, fetchPrintForm, fetchSingleDirectionLetter, resetCurrentDirectionLetter, resetCurrentMaterials, resetCurrentSupplier, resetEditebleRp, updateDirectionLetter } from "../../store/slices/directionLetters";
import { IFilesAttachResponse } from "../../api/files/types";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { fetchSupplier, selectAccount } from "../../store/slices/supplier";
import { useFormik } from "formik";
import { resetCurrentLimitFenceCard } from "../../store/slices/limitFenceCards";
import { approveDirectionLetters } from "../../api/directionLetters";
import { IApproveStockCardModal } from "../SingleStockCardPage";
import { ChakraModalProps } from "../../shared/types/modalProps";
import SuccessLogo from "../../images/png/success.png";
import { fetchSingleContract } from "../../store/slices/contracts";

export function SingleOrderLetterPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab"));
  const { downloadFile } = useUniversalDownloadFile();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(true)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disableApprove, setDisableApprove] = useState(false);
  const { isOpen: isSuccessModalOpen, onClose: onSuccesModalClose, onOpen: onSuccesModalOpen } = useDisclosure();

  const navigate = useNavigate();

  const { currentDirectionLetter, currentMaterials } = useAppSelector((state: RootState) => state.directionLetters);
  const { currentSupplier, selectedAccount } = useAppSelector((state: RootState) => state.supplier);

  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (id) {
      setIsSpinnerRuning(true)
      dispatch(fetchSingleDirectionLetter(id))
      .finally(() => setIsSpinnerRuning(false))
    }
  }, [dispatch, id]);

  const [files, setFiles] = useState(currentDirectionLetter?.files);


  const onSubmit = (values: any) => {};

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      supplier_director_full_name: currentDirectionLetter
        ? currentDirectionLetter.supplier_director_full_name
        : currentSupplier?.director_full_name,
      supplier_director_role: currentDirectionLetter
        ? currentDirectionLetter.supplier_director_role
        : currentSupplier?.director_role,
      payment_assignment: currentDirectionLetter
        ? currentDirectionLetter.payment_assignment
        : "",
      invoice_number: currentDirectionLetter?.invoice_number ?? "",
      invoice_date: "",
      order_number: currentDirectionLetter?.order_number ?? "",
      order_date: "",
      supplier_contract_number:
        currentDirectionLetter?.supplier_contract_number ?? "",
      supplier_contract_date: "",
      specification_number: currentDirectionLetter?.specification_number ?? "",
      specification_date: "",
      checking_account_guid: selectedAccount?.guid ?? "",
      checking_account_number: selectedAccount?.number ?? "",
      checking_account_bic: selectedAccount?.bic ?? "",
      relation_direction_letter_name: currentDirectionLetter?.relation_direction_letter_name ?? "",
      relation_direction_letter_date: currentDirectionLetter?.relation_direction_letter_date ?? "",
      supplier_guid: currentSupplier?.guid ?? currentDirectionLetter?.supplier_guid,
      amount: String(currentDirectionLetter?.amount) ?? "",
      tax : String(currentDirectionLetter?.tax) ?? "",
      supplier_invoice_number: currentDirectionLetter?.supplier_invoice_number ?? "",
      supplier_invoice_date: currentDirectionLetter?.supplier_invoice_date ?? "",
      materials: currentMaterials ?? [],
      advance_payment_type: "",
      advance_payment_schedule: []
    },
    onSubmit,
  });
  
  useEffect(() => {
    setFiles(currentDirectionLetter?.files)
  }, [currentDirectionLetter?.files])

  const isScheduleRowComplete = (row:any) => {
    return row && row.date !== "" && row.amount !== "";
  };

  const sumScheduleAmounts = (schedule:any) => {
    if (!schedule || schedule.length === 0) {
      return 0;
    }
    return schedule.reduce((sum:any, row:any) => {
      const amount = parseFloat(row.amount) || 0;
      return sum + amount
    }, 0);
  };
  const fieldsAreSet = () => {
    const {
      invoice_number,
      invoice_date,
      order_number,
      order_date,
      amount,
      tax,
      relation_direction_letter_name,
      relation_direction_letter_date,
      supplier_contract_number,
      supplier_contract_date,
      payment_assignment,
      supplier_invoice_number,
      supplier_invoice_date,
      advance_payment_type,
      advance_payment_schedule,
    } = formik.values;

    const basicFieldsAreSet =
      invoice_number.trim() !== "" &&
      invoice_date !== "" &&
      order_number.trim() !== "" &&
      order_date !== "" &&
      amount !== "" &&
      tax !== "" &&
      relation_direction_letter_name.trim() !== "" &&
      relation_direction_letter_date !== "" &&
      supplier_contract_number.trim() !== "" &&
      supplier_contract_date !== "" &&
      payment_assignment !== "" &&
      supplier_invoice_number.trim() !== "" &&
      advance_payment_type.trim() !== "" &&
      supplier_invoice_date !== "";

    let scheduleIsValid = true;
    let amountIsValid = true;
    if (advance_payment_type === "По графику") {
      if (!advance_payment_schedule || advance_payment_schedule.length === 0) {
        scheduleIsValid = false;
      }
      else {
        scheduleIsValid = advance_payment_schedule.every(isScheduleRowComplete);
        const scheduleSum = sumScheduleAmounts(advance_payment_schedule);
        amountIsValid = parseFloat(amount) === scheduleSum;
      }
    }

    return (
      basicFieldsAreSet &&
      (advance_payment_type !== "По графику" ||
        (advance_payment_type === "По графику" && scheduleIsValid && amountIsValid))
    );
  };


  useEffect(() => {
    setIsInvalid(!fieldsAreSet())
  },[formik.values])

  const filterMaterialFields = (material:any) => ({
    guid: material.guid,
    material_guid: material.material_guid,
    order_number: material.order_number,
    amount: material.amount,
  });
  
  useEffect(() => {
    const filteredMaterials = currentMaterials.map(filterMaterialFields);
    setFieldValue("materials", filteredMaterials);
  }, [currentMaterials, setFieldValue]);

  const fileAttach = (files: File[]) => {
    if (currentDirectionLetter) {
      dispatch(attachFilesToDirrectionLetter({id: currentDirectionLetter.guid, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  useEffect(() => {
    currentSupplier && setFieldValue("supplier_guid", currentSupplier.guid)
  }, [currentSupplier, setFieldValue])

  const handleSubmit = () => {
    setIsSpinnerRuning(true)
    currentDirectionLetter
      && dispatch(updateDirectionLetter({ id: currentDirectionLetter?.guid, params: formik.values }))
        .then((res: any) => {
          if (res.payload.success) {
            onSuccesModalOpen()
          }
        })
        .finally(() => setIsSpinnerRuning(false))
  }

  useEffect(() => {
    if (currentDirectionLetter) {
      dispatch(selectAccount({
        guid: currentDirectionLetter.checking_account_guid,
        bic: currentDirectionLetter.checking_account_bic,
        bank_name: currentDirectionLetter.checking_account_back_name,
        number: currentDirectionLetter.checking_account_number
      }))
      dispatch(fetchSingleContract(currentDirectionLetter.contract_id))
    }
  }, [currentDirectionLetter, dispatch])

  useEffect(() => {
    if (currentDirectionLetter) {
      setFieldValue("advance_payment_type", currentDirectionLetter.advance_payment_type ?? "")
      setFieldValue("advance_payment_schedule", currentDirectionLetter.advance_payment_schedule ?? [])
    }
  },[currentDirectionLetter, setFieldValue])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentDirectionLetter());
      dispatch(resetCurrentSupplier());
      dispatch(resetCurrentLimitFenceCard());
      dispatch(resetCurrentMaterials())
      dispatch(resetEditebleRp())
    }
  }, [])


  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/order-letters">
                Распорядительные письма
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                {currentDirectionLetter?.number}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/purchase/order-letters")} />
              <BlockTitle>{`Распорядительное письмо ${currentDirectionLetter?.number}`}</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MenuIcon color="red.500" />}
                  variant="outline"
                  borderColor="#FAD7D8"
                  _active={{ bg: "#FAD7D8", borderColor: "#F1868B" }}
                />
                <ChakraMenuList
                  list={[
                    {
                      title: "Отозвать оплату",
                      isDisabled: true,
                    },
                    {
                      title: "Печать заявки на выплату аванса",
                      isDisabled: currentDirectionLetter?.has_approved_visa,
                      onClick: () => downloadFile(currentDirectionLetter?.guid, setIsSpinnerRuning, fetchPrintForm, {type: "request_advance_payment"})
                    },
                    {
                      title: "Создать отражение факта поставки",
                      isDisabled: true,
                    },
                  ]}
                />
              </Menu>
              <OutlineButton
                onClick={() => setFilesModal(true)}
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
              >
                Файлы
              </OutlineButton>
              <OutlineButton isDisabled={currentDirectionLetter?.status !== "На согласовании (Подрядчик)" || disableApprove} onClick={onOpen}>Согласовать</OutlineButton>
              <OutlineButton isDisabled={currentDirectionLetter?.status != "Рабочий"}>Перезапустить</OutlineButton>
              <PrimaryButton 
                onClick={handleSubmit}
                isDisabled={currentDirectionLetter?.status !== "На согласовании (Подрядчик)" || !disableApprove || isInvalid || (formik.values.checking_account_guid === "" && (formik.values.checking_account_bic === "" || formik.values.checking_account_number === "") )}>
                Записать
                </PrimaryButton>
            </ButtonGroup>
          </Flex>

          <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
            <ChakraTabs
              tabs={
                currentDirectionLetter?.limit_card_id
                  ? singleOrderLetterPageTabs
                  : singleOrderLetterPageTabsWithoutMaterials
              }
            />
            <TabPanels>
              <TabPanel px={0} pb={0}>
                <DocumentPageBlock formik={formik} setFieldValue={setFieldValue} setDisableApprove={setDisableApprove} />
              </TabPanel>
              {currentDirectionLetter?.limit_card_id && (
                <TabPanel px={0} pb={0}>
                  <MaterialsTable isRp={true} />
                </TabPanel>
              )}
              <TabPanel px={0} pb={0}>
                <AdditionalInformationBlock setFilesModal={setFilesModal} formik={formik} setFieldValue={setFieldValue} setDisableApprove={setDisableApprove}/>
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <FilesBlock />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
        isDisabled={!!currentDirectionLetter && currentDirectionLetter.status !== "На согласовании (Подрядчик)"}
      />
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
      <ApproveStockCardModal isOpen={isOpen} onClose={onClose} setIsRunSpinner={setIsSpinnerRuning}/>
      <SuccessModal isOpen={isSuccessModalOpen} onClose={() => {
        onSuccesModalClose()
        window.location.reload();
      }} />
    </MainLayout>
  );
}

function ApproveStockCardModal({onClose, isOpen, setIsRunSpinner}: IApproveStockCardModal) {
  const dispatch = useAppDispatch();
  const { currentDirectionLetter } = useAppSelector(
    (state: RootState) => state.directionLetters
  );

  const navigate = useNavigate();

  const handleApprove = () => {
    setIsRunSpinner(true)
    currentDirectionLetter
      && dispatch(fetchApproveLetter(currentDirectionLetter.guid))
        .then((res: any) => {
         if (res.payload.success) {
          onClose();
          currentDirectionLetter && navigate(`/purchase/order-letters/${currentDirectionLetter.guid}`)
         }
        })
        .finally(() => {
          setIsRunSpinner(false)
        })
  }


  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Согласовать?
            </Text>
            <Text fontSize={16} fontWeight={400} color="#5C657E" align="center">Обратите внимание, что вносить изменения в данное распорядительное письмо больше нельзя.</Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="12px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={handleApprove}>
              Согласовать
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const SuccessModal = ({ onClose, isOpen }: ChakraModalProps) => {
  const navigate = useNavigate()
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={SuccessLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
             Распорядительное письмо успешно обновлено
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={() => {
            onClose();
            window.location.reload();
          }}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
